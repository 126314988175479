(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name services.factory:TeamStatistics
   *
   * @description
   *
   */
  angular
    .module('neo.services')
    .factory('TeamStatistics', TeamStatistics);

  function TeamStatistics($resource, EnvironmentConfig) {
    return $resource(EnvironmentConfig.API + '/statistics/teams');
  }
}());
